<template>
<div class="lg:w-1/2 w-full flex flex-col relative" id="left-form">
    <div class="flex-grow overflow-y-auto">
        <div class="flex justify-between px-8 py-4">
            <h3 class="font-medium">{{ $t("contact.pd") }}</h3>
            <div class="flex gap-2 text-red-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                </svg>
                <span class="font-medium text-black">{{ $t("contact.tips") }}</span>
            </div>
        </div>

        <!-- Image, name -->
        <div class="flex px-8 py-4 gap-4">
            <div v-if="this.$store.getters.getAvatar==null" id="userPhoto" @click="fileChooser" class="h-40 w-56 text-blue-800 border-gray-300 border-2 rounded-md flex flex-col items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-16 h-16">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z" />
                </svg>
                <span class="text-slate-600">{{ $t("contact.optional") }}</span>
                <input type="file" name="avatar" ref="avatar" @change="previewImage" accept="image/*" class="hidden"/>
            </div>

            <div v-else id="userPhotoView" class="h-40 w-56 text-blue-800 border-gray-300 border-2 rounded-md flex flex-col items-center justify-center">
                <div class="shadow-md h-16 w-16">
                    <img :src="this.$store.getters.getAvatar" alt="uploaded image" ref="previewImage" class="h-16 w-16"/>
                </div>
                <button @click="removeImage" class="">{{ $t("contact.rp") }}</button>
            </div>

            <div class="flex flex-grow flex-col">
                <div class="">
                    <label for="contact_name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                    <div class="mt-2">
                    <input id="contact_name" v-model="person.name" name="name" @input="updateInput" @focus="markOutput('name')" type="text" :placeholder="$t('ph.name')" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>

                <div>
                    <label for="contact_surname" class="block text-sm font-medium leading-6 text-gray-900">Surnames</label>
                    <div class="mt-2">
                    <input id="contact_surname" @input="updateInput" @focus="markOutput('surname')"  v-model="person.surname" name="surname" type="text" :placeholder="$t('ph.surnames')" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>

            </div>
        </div>

        <div class="px-8 py-4">
            <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
            <div class="mt-2">
            <input id="contact_email"  @input="updateInput" @focus="markOutput('email')" v-model="person.email" name="email" type="text" :placeholder="$t('ph.email')" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
        </div>
        <div class="px-8 py-4">
            <label for="address" class="block text-sm font-medium leading-6 text-gray-900">Address</label>
            <div class="mt-2">
            <input id="contact_address"  @input="updateInput" @focus="markOutput('address')" v-model="person.address" name="address" type="text" :placeholder="$t('ph.address')" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
        </div>
        <div class="px-8 py-4">
            <label for="phone" class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
            <div class="mt-2">
            <input id="contact_phone" @input="updateInput" @focus="markOutput('phone')" v-model="person.phone" name="phone" :placeholder="$t('ph.phone')" type="text" required class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
        </div>
        <div class="px-8 py-4">
            <div class="flex items-center">
                <input  name="driving-license" v-model="person.is_show_driving_license" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                <label for="driving-license" class="ml-3 block text-sm leading-6 text-gray-900">Driving License</label>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 ml-4" title="This is title text">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                </svg>
            </div>
        </div>

        <div class="px-8">
            <div class="mt-2">
            <input id="contact_driving_license"  v-show="showDL" @input="updateInput" @focus="markOutput('dl')" v-model="person.driving_license" name="dl"  type="text" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
            </div>
        </div>
    </div>
    <div class="h-20 border-t-[1px] border-slate-400 w-full px-8 mb-16 lg:mb-0">
        <button @click="goToAbout" class="ml-3 items-center rounded-md bg-blue-700 p-3 my-4 text-md font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right">Next</button>
    </div>

    <!-- Modal -->
    <div v-show="imagecroper" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <!--
            Background backdrop, show/hide based on modal state.

            Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
            Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <!--
                Modal panel, show/hide based on modal state.

                Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
                Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
            <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
              
                <div class="mt-3 text-center sm:mt-5">
                    <div class="mt-2">
                    <cropper :src="img" @change="change" class="cropper" ref="cropper"/>
                    </div>
                </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button @click="cropImage" type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2">Crop Image</button>
                <button @click="cropCancel" type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0">Cancel</button>
                </div>
            </div>
            </div>
        </div>
        </div>

      <!-- Modal -->
</div>

</template>

<script>
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import Vue from 'vue'
export default {
    name: 'contact-form',
    data(){
        return {
           img: null,
           imagecroper: false,
           croppedImage:null,
        }
    },
    methods:{
        showDL(){

        },
        goToAbout(){
            this.$store.dispatch('setMenuText', 'about')
        },
        change(){
            console.log('change fired')
        },
        cropImage(){
            const result = this.$refs.cropper.getResult()
            this.croppedImage = result.canvas.toDataURL()
            this.$store.dispatch('setAvatar', this.croppedImage)
            this.imagecroper = false
        },
        cropCancel(){
            this.imagecroper = false
            this.img = null
        },
        removeImage(){
            this.croppedImage = null
            this.$store.dispatch('setAvatar', null)
            this.img = null
        },
        fileChooser(){
            this.$refs.avatar.click()
        },
        previewImage(){
            const file = this.$refs.avatar.files[0]
            const fileType = file['type']
            const validImageTypes = ['image/jpeg', 'image/png']
            if(validImageTypes.includes(fileType)){
                this.img = window.URL.createObjectURL(file)
                this.imagecroper = true
            }
        },
        updateInput(){
            Vue.set(this.$store.getters.getPerson, this.person)
        },
        markOutput(input){
            this.$store.dispatch('setOpacity', input)
        }
    },
    components:{
        Cropper
    },
    beforeDestroy(){
       console.log('working destroy')
       this.$store.dispatch('submitContactForm', this.person)
    },
    computed:{
        person: {
            get: function(){
                    return this.$store.getters.getPerson
                },
            set: function(person){
                this.$store.dispatch('setPerson', person) 
            }
        }
    },
    
}
</script>

<style>
.cropper{
    height: 400px;
    width: 500px;
    background: #ddd;
}
</style>