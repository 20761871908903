<template>
    <div class="lg:w-1/2 w-full flex flex-col relative" id="left-form">
        <div class="flex-grow overflow-y-auto">
    
            <div class="flex justify-between px-8 py-4 text-blue-800">
                <button class="font-medium flex" @click="goToListPage">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    <span>{{ $t('pe.heading') }}</span>
                </button>
                <div class="flex gap-2 text-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg>
                    <span class="font-medium text-black">{{ $t('contact.tips') }}</span>
                </div>
            </div>
            <hr>
            <div class="flex mt-4">
                <div class="px-6 flex-1">
                    <label for="function" class="block text-sm font-medium leading-6 text-gray-900">{{ $t('pe.fn') }}</label>
                    <div class="mt-2">
                        <input v-model="experience.designation" @focus="markOutput('professional-designation-')" id="designation" @input="updateInput()" name="func" :placeholder="$t('ph.pma')" type="text"  required class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
                <div class="px-6 flex-1">
                    <label for="company" class="block text-sm font-medium leading-6 text-gray-900">Company</label>
                    <div class="mt-2">
                        <input v-model="experience.company" @focus="markOutput('professional-company-')" id="company" @input="updateInput()" name="company" placeholder="ATS" type="text" required class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
            </div>

            <div class="px-6 mt-4">
                <label for="city" class="block text-sm font-medium leading-6 text-gray-900">City</label>
                <div class="mt-2">
                    <input v-model="experience.city" @focus="markOutput('professional-city-')" id="city" @input="updateInput()" name="city" placeholder="Dhaka" type="text" required class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                </div>
            </div>

            <div class="px-6 mt-4">
                <div class="flex items-center">
                    <input v-model="experience.currently_working_here"  id="currently_working_here" name="ac" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                    <label for="ac" class="ml-3 block text-sm leading-6 text-gray-900">Actual charge</label>
                </div>
            </div>
            <div class="flex">
                <div class="px-6 flex-1">
                    <label for="startdate" class="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                    <div class="mt-2">
                        <input v-model="experience.start_date" @focus="markOutput('professional-start_date-')" id="startdate" @input="updateInput()" name="startdate"  type="date"  required class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
                <div class="px-6 flex-1">
                    <label for="enddate" class="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                    <div class="mt-2">
                        <input v-model="experience.end_date" id="enddate" @input="updateInput()" name="enddate" type="date" class="block w-full rounded-md border-0 py-3 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    </div>
                </div>
            </div>

            <div class="px-6 mt-4">
                <label for="jobdescription" class="block text-sm font-medium leading-6 text-gray-900">Description of my professional experience</label>
                <div :class="mce?'border-2 border-blue-500':''"><editor v-model="experience.description" @onfocus="markOutput('professional-description-')" @input="updateInput()" api-key="odnh4yh3965tvj0tilh63ul4aedgllcvh4in5mompcu3e381" :init="editorConfig"/></div>
            </div>

            <div class="px-6 mt-4 flex gap-4">
                <button @click="submitForm" class="lg:inline-flex items-center rounded-md bg-blue-700 py-3 px-6 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">OK</button>
                <button class="lg:inline-flex items-center rounded-md  py-3 px-6 bg-white shadow-2xl text-sm font-semibold text-blue-700 shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                </button>
            </div>

        </div>
        
    </div>
    
    </template>
    
    <script>
    import Editor from '@tinymce/tinymce-vue';
    import Vue from 'vue'
    export default {
        name: 'professional-experience',
        data(){
            return {
               editorConfig: {plugins:['advlist, lists'], toolbar:'bold italic underline numlist', menubar:false},
            }
        },
        methods:{
            goToListPage(){
                this.$store.dispatch('setMenuText', 'pexpl')
            },
            submitForm(){
                this.$store.dispatch('submitForm', this.experience)
                this.$store.dispatch('setMenuText', 'pexpl')
            },
            updateInput(){
               Vue.set(this.$store.getters.getpexperiences, this.$store.getters.geteditedindex, this.$store.getters.getepe)
            },
            markOutput(input){
                this.$store.dispatch('setProfessionalOpacity', input)
            }
        },
        components:{
            Editor
        },
        created(){
            let experience = this.$store.getters.getpexperiences[0]
            if(!experience){
                this.$store.dispatch('addProfessionalExperience')
            }
        },
        computed:{
            experience:{
                get: function(){
                    return this.$store.getters.getepe
                },
                set: function(editedExperience){
                    this.$store.dispatch('setExperience', editedExperience)
                }
            }, 
            mce(){
                return this.$store.getters.getMce
            }
        },
        beforeDestroy(){
            console.log('working')
        }
    }
    </script>