import axios from '../axios'
import Vue from 'vue'
export default {
    state:{
        interests:[],
        defaultInt:{ 
            interest:'Playing Card',
            crated_at: null,
            updated_at: null,
            order: 0,
            id: 0,
            resume_id: localStorage.getItem("did")
        },
        editingInt:{
            interest:'Playing Card',
            crated_at: null,
            updated_at: null,
            order: 0,
            id: 0,
            resume_id: localStorage.getItem("did")
        },
        intIndex: 0
    },
    getters:{
        getInts(state){
            return state.interests
        },
        getEditingInt(state){
            return state.editingInt
        },
        getDefaultInt(state){
            return state.defaultInt
        },
        getIntIndex(state){
            return state.intIndex
        }
    },
    mutations:{
        setInts(state,ints){
            state.interests = ints
        },
        setEditingInt(state, int){
            state.editingInt = int
        },
        setIntIndex(state, index){
            state.intIndex = index
        }
    },
    actions:{
        getIntList({commit}){
            let did = localStorage.getItem("did")
            axios.get("document/"+did+"/interests",)
            .then(response=>{
                commit('setInts', response.data.interests)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        setInts({commit}, ints){
            let ids = ints.map(item=>{
                return item.id
            })
            let formData ={
                id: ids
            }
            axios.post('document/sort-interests', formData)

            commit('setInts', ints)
        },
        removeInt({commit}, int){
            axios.post('document/interest/'+int.id+"/delete")
            .then(response=>{
                commit('setInts', response.data.interests)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        editInt({commit}, pl){
          let editingInt = Object.assign({},pl.int)
          commit('setEditingInt', editingInt)
          commit('setIntIndex', pl.index)
          let op = 'interest'+pl.index
          commit('setOpacity', op)
        },
        saveInt({commit, state},formData){
            if(state.intIndex == -1){
                axios.post('document/interest', formData)
                .then(response=>{
                    state.interests.push(response.data.interest)
                    commit('setMenuText', 'intlist')
                })
                .catch(error=>{
                    console.log(error.response)
                })
            }else{
                axios.post('document/interest/'+formData.id+'/edit', formData)
                .then(response=>{
                    commit('setMenuText', 'intlist')
                    Vue.set(state.interests, state.intIndex, state.editingInt)
                })
                .catch(error=>{
                    console.log(error.response)
                })
            }
           
        },
        setIntOpacity({commit}, opacity){
            commit('setIntOpacity', opacity)
        },
        addInt({commit, state}){
            let data = {
                resume_id: localStorage.getItem('did')
            }

            axios.post('document/add-interest', data)
            .then(response=>{
                commit('setInts', response.data.interests)
                let index = state.interests.length - 1
                commit('setIntIndex', index)
                commit('setMenuText', 'interest')
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        setIntIndex({commit}, index){
            commit('setIntIndex', index)
        }
    }
}