<template>
    <div class="hidden lg:block lg:w-1/2 px-4 py-4 bg-[#E7ECF9] overflow-y-auto">
                    <!-- Contact Section  -->
        
        <div :class="bgcolor+' p-4 flex'">
            <img :src="avatar" v-show="avatar"  class="h-16 w-16 border-0 mr-4" />
            <div class="flex-1">
                <div class="flex relative">
                    <p class="w-1/4">Email</p>
                    <p class="flex-grow">{{ email?email:$t("ph.email") }}</p>
                    <div :class="opacity == 'email'?'z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="contactInputFocus('contact_email')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>
                </div>
                <div class="flex relative">
                    <p class="w-1/4">Address</p>
                    <p class="flex-grow ">{{ address?address:$t("ph.address") }}</p>
                    <div :class="opacity == 'address'?'z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="contactInputFocus('contact_address')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>
                </div>
                <div class="flex relative">
                    <p class="w-1/4">Telephone</p>
                    <p class="flex-grow ">{{ phone?phone:$t("ph.phone") }}</p>
                    <div :class="opacity == 'phone'?'z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="contactInputFocus('contact_phone')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>
                </div>
            </div>
            <div class="flex-1">
                <div class="flex flex-col relative" id="name">
                    <h3 class="font-bold text-6xl">{{ name?name:$t("ph.name") }}</h3>
                    <div :class="opacity == 'name'? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="contactInputFocus('contact_name')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>
                </div>

                <div class="flex flex-col relative">
                    <h3 class="font-bold text-6xl">{{ surname?surname:$t("ph.surnames") }}</h3>
                    <div :class="opacity == 'surname'? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="contactInputFocus('contact_surname')">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </div>
                </div>
            </div>
             <!-- End contact section -->
        </div>
       
        <div class="bg-white p-4">
            <hr class=" h-px border-t-0 bg-white bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
           
            <div id="about-section" v-show="aboutIsVisible" class="relative mt-4">
                <p class="uppercase py-4">{{ $t('about.about') }}</p>
                <div :class="opacity == 'about'?'font-thin text-sm p-2':'font-thin text-sm'"></div>
                <div v-html="about"></div>
                <div :class="opacity == 'about'? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editAboutMce">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    </svg>
                </div>
            </div>

            <hr class=" h-px border-t-0 bg-white bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100 my-8" />
        </div>

        <!-- End About Section -->

        

        <!-- CV Tables -->
        <div class="flex px-12 py-8 bg-white">
            <!-- Table Left -->
            <div class="min-h-[60vh] lg:min-w-[260px] border-r-[1px] border-r-black">
                <div id="contact-section" class="">
                    <p class="uppercase py-4">{{ $t("contact.contact") }}</p>
           
                    <div :class="dlshow?'flex flex-col relative':'hidden'">
                        <p>Driving License: {{ dl?dl:$t("ph.dl") }}</p>
                        <div :class="opacity == 'dl'?'z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 z-30 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="contactInputFocus('contact_driving_license')">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </div>
                    </div>
                </div>
                
                <div id="skill-section" class="pr-4" >
                    <p class="uppercase py-4 border-t-[1px] border-t-black mt-8">Skills</p>
                    <div class="flex flex-col gap-2 ">
                        <div v-for="skill, index in skills" :id="'skill'+index" :class="opacity == 'skill'+index?'flex flex-col relative p-2':'flex flex-col relative hover:p-2'" :key="index">
                            <p class="font-bold">{{ skill.skill_name }}</p>
                            <span class="font-thin">{{skill.level}}</span>
                            <div :class="opacity == 'skill'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editMySkill(skill)">
                                <svg @click.stop="editMySkill(skill)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <!-- End Skill -->

                <div id="language-section" class="pr-4">
                <p class="uppercase py-4 border-t-[1px] border-t-black mt-8">Languages</p>
                <div class="flex flex-col gap-2">
                    <div v-for="lang, index in languages" :key="index" :id="'lang'+index" :class="opacity == 'lang'+index?'flex flex-col relative p-2':'flex flex-col relative hover:p-2'">
                        <p class="font-bold">{{lang.lang_name}}</p>
                        <span class="font-thin">{{ lang.level }}</span>
                        <div :class="opacity == 'lang'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editLanguage(lang)">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </div>
                    </div>
                    
                </div>
                </div>

                <!-- Languages End -->

                <div id="interest-section" class="pr-4">
                    <p class="uppercase py-4 border-t-[1px] border-t-black mt-8">Interests</p>
                    <div class="flex flex-col gap-2">
                        <div v-for="interest, index in interests" :key="index" :id="'interest'+index" :class="opacity == 'interest'+index?'flex flex-col relative p-2':'flex flex-col relative hover:p-2'" @click="editInterest(interest)">
                            <p class="font-thin">{{ interest.interest }}</p>
                            <div :class="opacity == 'interest'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- Interests End -->
            </div>
            <!-- End Table Left -->

            <!-- Table Right -->
            <div class="flex-grow pl-8">

                <div id="professional-exp-section">
                    <div class="py-4">
                        <p class="uppercase">Professional Experiences</p>
                    </div>
                        <div class="flex flex-col gap-2">
                            <div v-for="experience, index in experiences" :id="'professional-designation-'+index" class="professional" :key="experience.id">
                                <div class="relative">
                                    <p class="font-semibold">{{ experience.designation }}</p>
                                    <div :class="opacity == 'professional-designation-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editProfessionalTraining(experience, 'designation')">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="flex font-thin text-sm">
                                    <div class="relative">
                                        <p class="">{{ experience.company }}</p>
                                        <div :class="opacity == 'professional-company-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editProfessionalTraining(experience, 'company')">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </div>
                                    </div> <span class=""> &nbsp;/&nbsp; </span>
                                    <div class="relative ">
                                        <p class="">{{ experience.city }}</p>
                                        <div :class="opacity == 'professional-city-'+index?'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editProfessionalTraining(experience, 'city')">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="relative">
                                    <p class="font-thin text-sm">{{ experience.start_date }}</p>
                                    <div :class="opacity == 'professional-start_date-'+index?'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editProfessionalTraining(experience, 'startdate')">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </div>
                                </div>
                                <div class="relative">
                                    <div class="font-thin text-sm" v-html="experience.description"></div>
                                    <div :class="opacity == 'professional-description-'+index?'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editProfessionalTrainingMce(experience, 'description')">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div id="academic-section" class="border-t-[1px] border-black mt-6">
                    <div class="py-4">
                        <p class="uppercase">Academic Training</p>
                    </div>
                <div class="flex flex-col gap-2">
                    <div class="academic" v-for="academic, index in academicExperiences" :id="'academic-diploma-'+index" :key="index">
                        <div class="relative">
                            <p class="font-bold">{{ academic.diploma }}</p>
                            <div :class="opacity == 'academic-diploma-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editAcademicTraining(academic, 'diploma')">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex font-thin text-sm">
                            <div class="relative">
                                <p class="">{{ academic.institute_name }}</p>
                                <div :class="opacity == 'academic-institution-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editAcademicTraining(academic, 'institution')">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </div>
                            </div> <span class="">&nbsp;/&nbsp;</span>
                            <div class="relative ">
                                <p class="">{{academic.city}}</p>
                                <div :class="opacity == 'academic-city-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editAcademicTraining(academic, 'city')">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="relative">
                            <p class="font-thin text-sm">{{ academic.passing_year }}</p>
                            <div :class="opacity == 'academic-enddate-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editAcademicTraining(academic, 'enddate')">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                        <div class="relative">
                            <div class="font-thin text-sm" v-html="academic.description"></div>
                            
                            <div :class="opacity == 'academic-description-'+index? 'h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800':'opacity-0 hover:opacity-75 h-full w-full absolute top-0 right-0 border-2 border-sky-700 text-sky-800'" @click="editAcademicTrainingMce(academic, 'jobdescription')">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 float-right cursor-pointer">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <!-- End Academic Training -->

            </div>

            <!-- End Table Right -->
        </div>
        <!-- End CV Tables -->
    </div>
</template>

<script>
export default {
    name: 'temp-academic',
    data(){
        return {
        }
    },
    methods:{
        focusSkillInput(skill, index){
            console.log(skill)
        },
        contactInputFocus(input){
            if( this.$store.getters.getMenuText == 'contact'){
                    this.$store.dispatch('focusInput', input)
                }else {
                    this.$store.dispatch('setMenuText', 'contact')
                    setTimeout(() => {
                        this.$store.dispatch('focusInput', input)
                    }, 1000);
                }
        },
        editInterest(interest){
            let index = this.interests.indexOf(interest)
                let payload = {
                    index: index,
                    int:interest
                }
                this.$store.dispatch('editInt', payload)

                this.$store.dispatch('setMenuText', 'interest')
        },
        editLanguage(language){
            let index = this.languages.indexOf(language)
                let payload = {
                    index: index,
                    lang:language
                }
                this.$store.dispatch('editLang', payload)

                this.$store.dispatch('setMenuText', 'lang')
        },
        editMySkill(skill){
            let index = this.skills.indexOf(skill)
                let payload = {
                    index: index,
                    skill:skill
                }
                this.$store.dispatch('editSkill', payload)

                this.$store.dispatch('setMenuText', 'skill')
        },
        editAcademicTraining(academic, input){
            let index = this.academicExperiences.indexOf(academic)
                let payload = {
                    index: index,
                    experience:academic
                }
                this.$store.dispatch('editAcademicExperience', payload)
                if( this.$store.getters.getMenuText == 'academic'){
                    this.$store.dispatch('focusInput', input)
                }else {
                    this.$store.dispatch('setMenuText', 'academic')
                    setTimeout(() => {
                        this.$store.dispatch('focusInput', input)
                    }, 1000);
                }
                
        },
        editAcademicTrainingMce(academic, input){
            let index = this.academicExperiences.indexOf(academic)
                let payload = {
                    index: index,
                    experience:academic
                }
                this.$store.dispatch('editAcademicExperience', payload)
                if( this.$store.getters.getMenuText == 'academic'){
                    this.$store.dispatch('focusMce', input)
                }else {
                    this.$store.dispatch('setMenuText', 'academic')
                    setTimeout(() => {
                        this.$store.dispatch('focusMce', input)
                    }, 1000);
                }
                
        },
        editProfessionalTraining(professional, input){
            let index = this.experiences.indexOf(professional)
                let payload = {
                    index: index,
                    experience:professional
                }
                this.$store.dispatch('editExperience', payload)
                if( this.$store.getters.getMenuText == 'pexp'){
                    this.$store.dispatch('focusInput', input)
                }else {
                    this.$store.dispatch('setMenuText', 'pexp')
                    setTimeout(() => {
                        this.$store.dispatch('focusInput', input)
                    }, 1000);
                }
                
        },

        editProfessionalTrainingMce(professional, input){
            let index = this.experiences.indexOf(professional)
                let payload = {
                    index: index,
                    experience:professional
                }
                this.$store.dispatch('editExperience', payload)
                if( this.$store.getters.getMenuText == 'pexp'){
                    this.$store.dispatch('focusMce', input)
                }else {
                    this.$store.dispatch('setMenuText', 'pexp')
                    setTimeout(() => {
                        this.$store.dispatch('focusMce', input)
                    }, 1000);
                }
                
        },
        editAboutMce(){
            if (this.$store.getters.getMenuText == 'about'){
                this.$store.dispatch('focusMce', 'description')
            }else{
                this.$store.dispatch('setMenuText', 'about')
                setTimeout(() => {
                    this.$store.dispatch('focusMce', 'description')
                }, 1000);
            }
            
            
        }

    },
    created(){
    },
    computed:{
        dlshow(){
            return this.$store.getters.getDlshow
        },
        avatar(){
            return this.$store.getters.getAvatar
        },
        name(){
            return this.$store.getters.getName
        },
        surname(){
            return this.$store.getters.getSurname
        },
        email(){
            return this.$store.getters.getEmail
        },
        address(){
            return this.$store.getters.getAddress
        },
        phone(){
            return this.$store.getters.getPhone
        },
        dl(){
            return this.$store.getters.getDrivingLicense
        },
        about(){
            return this.$store.getters.getAbout
            
        },
        aboutIsVisible(){
            return this.$store.getters.getAboutShow
        },
        opacity(){
              let element = document.getElementById(this.$store.getters.getOpacity)
              if( element !== null){
                element.scrollIntoView({behavior:"smooth", block: "center"})
              }
            
            return this.$store.getters.getOpacity
        },
        experiences(){
            return this.$store.getters.getpexperiences
        },
        academicExperiences(){
            return this.$store.getters.getAcademicExperiences
        },
        skills(){
            return this.$store.getters.getSkills
        },
        languages(){
            return this.$store.getters.getLangs
        },
        interests(){
            return this.$store.getters.getInts
        },
        bgcolor(){
            return this.$store.getters.getBgColor
        }
    }
}
</script>

<style>
ol {
   list-style-type: decimal;
   padding-left: 16px;
}
</style>