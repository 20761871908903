import axios from '../axios'
import Vue from 'vue'
export default {
    state:{
        skills:[],
        defaultSkill:{ 
            id: 0,
            skill_name:'Laravel',
            level:'High',
            crated_at: null,
            updated_at: null,
            order: 0,
            resume_id: localStorage.getItem("did")
        },
        editingSkill:{
            skill_name:'Nodejs',
            level:'Medium',
            crated_at: null,
            updated_at: null,
            order: 0,
            id: 0,
            resume_id: localStorage.getItem("did")
        },
        skillIndex: 0,
        skillOpacity: ''
    },
    getters:{
        getSkills(state){
            return state.skills
        },
        getEditingSkill(state){
            return state.editingSkill
        },
        getDefaultSkill(state){
            return state.defaultSkill
        },
        getSkillIndex(state){
            return state.skillIndex
        }
    },
    mutations:{
        setSkills(state,skills){
            state.skills = skills
        },
        setEditingSkill(state, skill){
            state.editingSkill = skill
        },
        setSkillIndex(state, index){
            state.skillIndex = index
        }
    },
    actions:{
        setSkillIndex({commit}, index){
            commit('setSkillIndex', index)
        },
        getSkillList({commit}){
            let did = localStorage.getItem("did")
            axios.get("document/"+did+"/skills",)
            .then(response=>{
                commit('setSkills', response.data.skill)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        setSkills({commit}, skills){
            let ids = skills.map(item=>{
                return item.id
            })
            let formData ={
                id: ids
            }
            axios.post('document/sort-skills', formData)

            commit('setSkills', skills)
        },
        removeSkill({commit}, skill){
            axios.post('document/skill/'+skill.id+"/delete")
            .then(response=>{
                commit('setSkills', response.data.skills)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        editSkill({commit, state}, pl){
            let editingSkill = Object.assign({},pl.skill)
            commit('setEditingSkill', editingSkill)
            let op = 'skill'+pl.index
            commit('setOpacity', op)
        },
        // setExperience({commit,state},pl){
        //     Vue.set(state.experiences, state.editedIndex, pl)
        // },
        saveSkill({commit, state},formData){
            if(state.skillIndex == -1){
                axios.post('document/skill', formData)
                .then(response=>{
                    console.log(response)
                    commit('setMenuText', 'skills')
                })
                .catch(error=>{
                    console.log(error.response)
                })
            }else{
                axios.post('document/skill/'+formData.id+'/edit', formData)
                .then(response=>{
                    commit('setMenuText', 'skilllist')
                    Vue.set(state.skills, state.skillIndex, state.editingSkill)
                })
                .catch(error=>{
                    console.log(error.response)
                })
            }
           
        },
        setSkillOpacity({commit}, opacity){
            commit('setSkillOpacity', opacity)
        },
        addSkill({commit, state}){
            
            let data = {
                resume_id: localStorage.getItem('did')
            }

            axios.post('document/add-skill', data)
            .then(response=>{
                commit('setSkills', response.data.skills)
                let index = state.skills.length - 1
                
                commit('setSkillIndex', index)
                commit('setMenuText', 'skill')
            })
            .catch(error=>{
                console.log(error.response)
            })
           
        }
    }
}