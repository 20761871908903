<template>
    <div class="lg:w-1/2 w-full flex flex-col relative" id="left-form">
        <div class="flex-grow overflow-y-auto">
    
            <div class="flex justify-between px-8 py-4">
                <h3 class="font-medium">About</h3>
                <div class="flex gap-2 text-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg>
                    <span class="font-medium text-black">Tips</span>
                </div>
            </div>
    
            <!-- Image, name -->
            <div class="px-8 py-4 ">
                <div>
                    <p>Write 2-4 short, snappy sentences to interest the reader! Mention your role, experience and most importantly your greatest achievements, best qualities and skills.</p>
                </div>
                <div class="mt-4">
                    <div class="flex items-center">
                        <input id="isVisible" v-model="isvisible" name="isvisible" type="checkbox" @change="aboutShowHide" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600">
                        <label for="isVisible" class="ml-3 block text-sm leading-6 text-gray-900">Show the about part on my resume</label>
                    </div>
                </div>
                
                <div class="">
                        <div class="mt-2">
                            <div :class="mce?'border-2 border-blue-500':''"><editor @onfocus="markOutput('about')" @input="updateInput('about')"  api-key="odnh4yh3965tvj0tilh63ul4aedgllcvh4in5mompcu3e381" :init="{plugins:['advlist, lists'],toolbar:'bold italic underline', menubar: false}" v-model="person.about"/></div>
                            <!-- <textarea id="about" name="about" rows="3" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea> -->
                        </div>
                </div>
            </div>
    
            
        </div>
        <div class="h-20 border-t-[1px] border-slate-400 w-full px-8 mb-16 lg:mb-0">
            <button @click="goToContact" class="ml-3 items-center rounded-md bg-blue-700 p-3 my-4 text-md font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Previous</button>
            <button @click="goToProExp" class="ml-3 items-center rounded-md bg-blue-700 p-3 my-4 text-md font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right">Next</button>
        </div>
    </div>
    
</template>
    
<script>
    import Editor from '@tinymce/tinymce-vue'
    import Vue from 'vue'
    export default {
        name: 'contact-form',
        data(){
            return {
                isvisible: true
            }
        },
        components:{
            Editor
        },
        methods:{
            aboutShowHide(){
                this.isvisible?this.$store.dispatch('setAboutShow',1):this.$store.dispatch('setAboutShow',0)
            },
            goToContact(){
                this.$store.dispatch('setMenuText', 'contact')
            },
            goToProExp(){
                this.$store.dispatch('setMenuText', 'pexp')
            },
            markOutput(input){
                this.$store.dispatch('setOpacity', input)
            },
            updateInput(){
                Vue.set(this.$store.getters.getPerson, this.person)
            },
        },
        beforeDestroy(){
        
        this.$store.dispatch('submitContactForm', this.person)
        },
        computed:{
            mce(){
                return this.$store.getters.getMce
            },
            person: {
            get: function(){
                    return this.$store.getters.getPerson
                },
            set: function(person){
                this.$store.dispatch('setPerson', person) 
            }
        }
        }
    }
</script>