import axios from 'axios'
let BASE_URL = ''
if(location.hostname == 'localhost'){
    BASE_URL = 'http://apirapidocv.local/api/'
}else if(location.hostname == 'c5.krakentech.net'){
    BASE_URL = 'https://api.c5.krakentech.net/api/'
}

const RapidoAxios = axios.create({
    baseURL:BASE_URL
})

export default RapidoAxios