import Vue from 'vue';
import Vuex from 'vuex';
import axios from '../axios';
import contact from './contact'
import professionalexperience from './pexp'
import academiexperience from './aexp'
import skill from './skill'
import lang from './lang'
import inter from './interests'
Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        contact,
        professionalexperience,
        academiexperience,
        skill,
        lang,
        inter
    },

    state:{
        menuText: 'contact',
        template:'corporate',
        token: null,
        opacity: '',
        mce: null,
        bgcolor: 'bg-white',
        professional_experiences:[
                {
                    designation: "Products Marketing Assistant",
                    company: "ATS",
                    city: "Dhaka",
                    start_date: "07/11/2021",
                    end_date: "07/11/2023"
                },
                {
                    designation: "Products Marketing Assistant",
                    company: "ATS",
                    city: "Dhaka",
                    start_date: "07/11/2021",
                    end_date: "07/11/2023"
                }
            ],
        academic_training:[
                {
                    institute:"Bangladesh University",
                    diploma:"Bsc in CSE",
                    city: "Dhaka",
                    passing_year:"2014",
                    description:""
                },
                {
                    institute:"Bogra Polytechnic Institute",
                    diploma:"Bsc in CSE",
                    city: "Bogra",
                    passing_year:"2009",
                    description:""
                }

            ],
        skills:[
            {skill_name:"Laravel", level:"Expert"},
            {skill_name:"Tailwind", level:"Medium"}
        ],
        languages:[
            {language:"English", level:"fluent"},
            {language:"Hindi", level:"Basic"}
        ],
        interests:["plyaing card", "Lodu", "Coding", "Debugging"]
    },
    getters:{
        getToken(state){
            return state.token
        },
        getMenuText(state){
            return state.menuText
        },
        getOpacity(state){
            return state.opacity
        },
        getMce(state){
            return state.mce
        },
        getTemplateName(state){
            return state.template
        },
        getBgColor(state){
            return state.bgcolor
        }
    },
    mutations:{
        setToken(state,token){
            state.token = token
        },
        logout(state){
            state.token = null
            state.email = null
            localStorage.clear()
        },
        setMenuText(state, text){
            state.menuText = text
        },
        setMce(state, input){
            state.mce = input
        },
        setOpacity(state, opacity){
            state.opacity = opacity
        },
        setTemplateName(state, template){
            state.template = template
        },
        setBgColor(state, color){
            state.bgcolor = color
        }
    },
    actions:{
        colorChooser({commit}, color){
            commit('setBgColor', color)
        },
        setTemplateName({commit}, template){
            commit('setTemplateName', template)
            localStorage.setItem('template', template)
            let data ={
                name: template,
                resume_id: localStorage.getItem('did')
            }
            axios.post('/document/change-template', data)
            .then(response=>{
                console.log(response)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        setMenuText({commit}, text){
            commit('setMenuText', text)
        },
        createOrRetrieveCV({commit}){
            let documentId = localStorage.getItem('documentId')
            if(documentId == null || documentId == ""){
                axios.post('/document/create')
                .then(response=>{
                    documentId = response.data.uid
                    localStorage.setItem('documentId', documentId)
                    localStorage.setItem('did', response.data.id)
                    localStorage.setItem('template', response.data.template)
                    commit('setName', response.data.contact.name)
                    commit('setSurname', response.data.contact.surname)
                    commit('setAddress', response.data.contact.address)
                    commit('setEmail', response.data.contact.email)
                    commit('setPhone', response.data.contact.phone)
                    commit('setDrivingLicense', response.data.contact.driving_license)
                    commit('setAvatar', response.data.contact.avatar)
                    commit('setDlShow', response.data.contact.is_show_driving_license)
                    commit('setAbout', response.data.contact.about)
                    commit('setAcademicExperiences', response.data.academic)
                    commit('setpexperiences', response.data.professional)
                    commit('setSkills', response.data.skill)
                    commit('setLangs', response.data.language)
                    commit('setInts', response.data.interest)
                    window.history.pushState('', '', '/?documentId='+documentId)
                })
                .catch(error=>{
                    console.log(error)
                })
            }
            window.history.pushState('', '', '/?documentId='+documentId)
        },
        focusInput({commit},input){
            commit('setMce', null)
            document.getElementById(input).focus()
        },
        focusMce({commit},input){
            commit('setMce', input)
        },
        cancelLogout({commit}){
            commit('setLogout')
        },
        
        setLogoutTimer({commit},exprirationTime){
            setTimeout(()=>{
                commit('logout')
            }, exprirationTime)
        },
        tryAutoLogin({commit,dispatch}){
            const token = localStorage.getItem('token')
            if(!token){
                localStorage.removeItem('currentUrl')
                dispatch('logout')
                return
            }
            const expirationDate = localStorage.getItem('expires_in')
            const now = new Date()
            if(now.getTime() > new Date(expirationDate).getTime()){
                localStorage.removeItem('currentUrl')
                dispatch('logout')
                return
            }
            const expires_in = new Date(expirationDate).getTime()
            const timeoutIn = expires_in - now.getTime()
            dispatch('setLogoutTimer', timeoutIn)
            const email = localStorage.getItem('email')
            commit('setToken',token)
            commit('setEmail',email)
           
        },
        login({commit,dispatch}, formData){
            axios.post('/login',formData)
            .then(response=>{
                const now = new Date()
                const exprirationDate = new Date(now.getTime() + 3600000)
                localStorage.setItem('token', response.data.access_token)
                localStorage.setItem('email', response.data.email)
                localStorage.setItem('expires_in', exprirationDate)

                commit('setEmail', response.data.email)
                commit('setToken', response.data.access_token)
                if(parseInt(response.data.isAdmin) == 1){
                    commit('setUserId', response.data.userId)
                }

                dispatch('setLogoutTimer', response.data.expires_in * 1000)
                dispatch('setAlert', 'Welcome back!')
                return true
            })
            .catch(function(error){
                dispatch('setErrorAlert', 'Invalid Login')
            })
        },
        logout({commit}){
            localStorage.removeItem('token')
            localStorage.removeItem('email')
            localStorage.removeItem('expires_in')
            commit('setUserId', 0)
            commit('logout') 
            localStorage.clear()
        },
        
        register({dispatch,commit},formData){
            axios.post('register',formData)
            .then( response => {
                commit('setAlertType', '#0E89F0')
                dispatch('setAlert', response.data.message)
                dispatch('toLoginPage')
            })
            .catch( error => {
                let message =''
                if(error.response.data.errors.name){
                    message += error.response.data.errors.name[0]
                }
                if(error.response.data.errors.email){
                    message+='\n'+error.response.data.errors.email[0]
                }
                if(error.response.data.errors.password){
                    message+='\n'+ error.response.data.errors.password[0]
                }
                commit('setAlertType', '#F02C0E')
                dispatch('setAlert', message)
            })
        },
    }
})