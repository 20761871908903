import axios from '../axios'
import Vue from 'vue'
export default {
    state:{
        languages:[],
        defaultLang:{ 
            lang_name:'Bangoli',
            level:'Intermediate',
            crated_at: null,
            updated_at: null,
            order: 0,
            id: 0,
            resume_id: localStorage.getItem("did")
        },
        editingLang:{
            lang_name:'Bangoli',
            level:'Intermediate',
            crated_at: null,
            updated_at: null,
            order: 0,
            id: 0,
            resume_id: localStorage.getItem("did")
        },
        langIndex:0
    },
    getters:{
        getLangs(state){
            return state.languages
        },
        getEditingLang(state){
            return state.editingLang
        },
        getDefaultLang(state){
            return state.defaultLang
        },
        getLangIndex(state){
            return state.langIndex
        }
    },
    mutations:{
        setLangs(state,langs){
            state.languages = langs
        },
        setEditingLang(state, lang){
            state.editingLang = lang
        },
        setLangIndex(state, index){
            state.langIndex = index
        }
    },
    actions:{
        getLangList({commit}){
            let did = localStorage.getItem("did")
            axios.get("document/"+did+"/langs",)
            .then(response=>{
                commit('setLangs', response.data.lang)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        setLangs({commit}, langs){
            let ids = langs.map(item=>{
                return item.id
            })
            let formData ={
                id: ids
            }
            axios.post('document/sort-langs', formData)

            commit('setLangs', langs)
        },
        removeLang({commit}, lang){
            axios.post('document/lang/'+lang.id+"/delete")
            .then(response=>{
                commit('setLangs', response.data.langs)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        editLang({commit, state}, pl){
          let editingLang = Object.assign({},pl.lang)
          commit('setEditingLang', editingLang)
          commit('setLangIndex', pl.index)
          let op = 'lang'+state.langIndex
          commit('setOpacity', op)
        },
        
        saveLang({commit, state},formData){
            if(state.langIndex == -1){
                axios.post('document/lang', formData)
                .then(response=>{
                    console.log(response)
                    commit('setMenuText', 'langlist')
                })
                .catch(error=>{
                    console.log(error.response)
                })
            }else{
                axios.post('document/lang/'+formData.id+'/edit', formData)
                .then(response=>{
                    commit('setMenuText', 'langlist')
                    Vue.set(state.languages, state.langIndex, state.editingLang)
                })
                .catch(error=>{
                    console.log(error.response)
                })
            }
           
        },
        setLangOpacity({commit}, opacity){
            commit('setLangOpacity', opacity)
        },
        addLang({commit, state}){
            let data = {
                resume_id: localStorage.getItem('did')
            }

            axios.post('document/add-lang', data)
            .then(response=>{
                commit('setLangs', response.data.languages)
                let index = state.languages.length - 1
                commit('setLangIndex', index)
                commit('setMenuText', 'lang')
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        setLangIndex({commit}, index){
            commit('setLangIndex', index)
        }
    }
}