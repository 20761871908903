<template>
    <div class="lg:w-1/2 w-full flex flex-col relative" id="left-form">
        <div class="flex-grow overflow-y-auto">
    
            <div class="flex justify-between px-8 py-4">
                <h3 class="font-medium">All Langs</h3>
                <div class="flex gap-2 text-red-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                    </svg>
                    <span class="font-medium text-black">{{ $t('contact.tips') }}</span>
                </div>
            </div>
    
            <!-- Image, name -->
            <div class="px-8 py-4">
                <draggable v-model="langs" group="people" @start="drag=true" @end="drag=false">
                    <transition-group>
                        <div v-for="element, index in langs" :key="element.id">
                            <div @click="editLang(index)" class="border-t-4 border-t-black shadow-md py-4 px-6 mt-4 flex">
                                <div class="py-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
                                    </svg>
                                </div>
                                <div class="flex-1 flex flex-col px-4">
                                    <div>{{element.lang_name}}</div>
                                    <div>{{ element.level }}</div>
                                </div>

                                <div class="py-2">
                                    <svg @click.stop="removeLang(element)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                </draggable>

                <button @click="addLang" class="border-dotted border-4 border-gray py-4 px-6 mt-4 text-center hover:bg-indigo-500 hove:border-white hover:text-white hover:cursor-pointer w-full">
                    Add New
                </button>
            </div>
    
            
        </div>
        <div class="h-20 border-t-[1px] border-slate-400 w-full px-8 mb-16 lg:mb-0">
            <button @click="goToSkillPage" class="ml-3 items-center rounded-md bg-blue-700 p-3 my-4 text-md font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">Previous</button>
            <button @click="goToInterestPage" class="ml-3 items-center rounded-md bg-blue-700 p-3 my-4 text-md font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 float-right">Next</button>
        </div>
    </div>
    
    </template>
    
    <script>
    import draggable from 'vuedraggable'

    export default {
        name: 'lang-list',
        data(){
            return {
            }
        },
        methods:{
            addLang(){
                this.$store.dispatch('addLang')
            },
            removeLang(lang){
                this.$store.dispatch('removeLang', lang)
            },
            editLang(index){
                this.$store.dispatch('setLangIndex', index)
                this.$store.dispatch('setMenuText', 'lang')
            },
            goToSkillPage(){
                this.$store.dispatch('setMenuText', 'skill')
            },
            goToInterestPage(){
                this.$store.dispatch('setMenuText', 'interest')
            },
           
        },
        components:{
            draggable
        },
        computed:{
            langs: {
                get: function(){
                    return this.$store.getters.getLangs
                },
                set: function(newValue){
                    this.$store.dispatch('setLangs', newValue)
                }  
            }
        }

    }
    </script>