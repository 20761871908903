import axios from '../axios'
import Vue from 'vue'
export default {
    state:{
        aexperiences:[],
        dae:{ //Default Academic Experience
            id: 0,
            diploma:'Computer Science and Engineering',
            institute_name:'Bangladesh University',
            city:'Dhaka',
            completed:false,
            passing_year:'2016-01',
            description:'This is <i>demo job desicription</i> <b>bold text inside it</b>',
            crated_at: null,
            updated_at: null,
            order: 0,
            resume_id: localStorage.getItem("did")
        },
        eae:{ //Editable Academic Experience
            diploma:'Computer Science and Engineering',
            institute_name:'Bangladesh University',
            city:'Dhaka',
            completed:false,
            passing_year: '2016-01',
            description:'This is <i>demo job desicription</i> <b>bold text inside it</b>',
            crated_at:null,
            updated_at:null,
            order: 0,
            id: 0,
            resume_id: localStorage.getItem("did")
        },
        editedAcademicIndex: 0,
    },
    getters:{
        getAcademicExperiences(state){
            return state.aexperiences
        },
        geteae(state){
            return state.eae
        },
        getdae(state){
            return state.dae
        },
        getEditedAcademicIndex(state){
            return state.editedAcademicIndex
        }
    },
    mutations:{
        setAcademicExperiences(state,experiences){
            state.aexperiences = experiences
        },
        setEditingAcademicExperience(state, experience){
            state.eae = experience
        },
        setEditingAcademicIndex(state, index){
            state.editedAcademicIndex = index
        }
    },
    actions:{
        getAcademiclist({commit}){
            let did = localStorage.getItem("did")
            axios.get("document/"+did+"/academics",)
            .then(result=>{
                commit('setAcademicExperiences', result.data.academic)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        setAcademicExperiences({commit}, aexp){
            let ids = aexp.map(item=>{
                return item.id
            })
            let formData ={
                id: ids
            }
            axios.post('document/sort-academic-experiences', formData)

            commit('setAcademicExperiences', aexp)
        },
        removeAcademicExperience({commit}, experience){
            axios.post('document/academic/'+experience.id+"/delete")
            .then(response=>{
                commit('setAcademicExperiences', response.data.experiences)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        editAcademicExperience({commit}, pl){
          let editingExperience = Object.assign({},pl.experience)
          commit('setEditingAcademicExperience', editingExperience)
          commit('setEditingAcademicIndex', pl.index)
        },
        setExperience({commit,state},pl){
            Vue.set(state.experiences, state.editedIndex, pl)
        },
        saveAcademicExperience({commit},formData){

            axios.post('document/academic', formData)
            .then(response=>{
                console.log(response)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        setAcademicOpacity({commit, state}, opacity){
            let op = opacity+state.editedAcademicIndex
            commit('setOpacity', op)
        },
        addAcademicExperience({commit, state}){
            let data = {
                resume_id : localStorage.getItem("did")
            }
            axios.post('document/add-academic-experience', data)
            .then(response=>{
                
                commit('setAcademicExperiences', response.data.experiences)
                let index = state.aexperiences.length - 1
                let editingExperience = Object.assign({},response.data.experience)
                commit('setEditingAcademicExperience', editingExperience)
                commit('setEditingAcademicIndex', index)
                commit('setMenuText', 'academic')
            })
            .catch(error=>{
                console.log(error.response)
            })


        }
    }
}