export default{
    contact:{
        name: 'Name',
        surnames: 'Surnames',
        email: 'Email',
        address: 'Address',
        phone: 'Phone',
        dl: 'Driving License',
        pd: 'Personal Details',
        optional: 'Optional',
        next:'Next',
        tips: 'Tips',
        contact: 'Contact',
        rp:"Remove Photo"
    },
    ph:{
        name: 'Chitano',
        surnames: 'Kumar Sarker',
        email: 'chitanokumar@gmail.com',
        address: 'Shadullahpur 5730, Gaibandha, Bangladesh',
        phone: '+8801710048212',
        dl: 'Driving License',
        pma: 'Products Marketing Assistant',
        about: 'I am Chitano Kumar Sarker from Bangladesh. I love to do programming, playing card and driving motor byke. I also love to help people to fix there application bug and operating system related bugs.',
    },
    about: {
        about:'About',
        abouttopmessage: 'Write 2-4 short, snappy sentences to interest the reader! Mention your role, experience and most importantly your greatest achievements, best qualities and skills.',
        stapomr: 'Show the about part on my resume',
    },
    pe:{
        heading: 'Professional Experience',
        fn: 'Functions',

    }
}