import './assets/app.css'
import Vue from 'vue'
import App from './App.vue'
import i18n from './lang/i18n'

Vue.config.productionTip = false
import store from './store'


new Vue({
  store,
  render: h => h(App),
  i18n
}).$mount('#app')
