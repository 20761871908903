import axios from '../axios'
import Vue from 'vue'
export default {
    state:{
        pexperiences:[],
        dpe:{ //Default Professional Experience
            id: 0,
            designation:'Web Design and Development',
            company:'ATS',
            city:'Dhaka',
            currently_working_here:false,
            start_date:'2016-01-01',
            end_date:'2020-01-30',
            description:'This is <i>demo job desicription</i> <b>bold text inside it</b>',
            crated_at: null,
            updated_at: null,
            order: 0,
            resume_id: localStorage.getItem("did")
        },
        epe:{ //Editable Professional Experience
            id: 0,
            designation:'Web Design and Development',
            company:'ATS',
            city:'Dhaka',
            currently_working_here:false,
            start_date: '2016-01-01',
            end_date: '2020-01-30',
            description:'This is <i>demo job desicription</i> <b>bold text inside it</b>',
            crated_at:null,
            updated_at:null,
            order: 0,
            resume_id: localStorage.getItem("did")
        },
        editedIndex: 0
    },
    getters:{
        getpexperiences(state){
            return state.pexperiences
        },
        getepe(state){
            return state.epe
        },
        getdpe(state){
            return state.dpe
        },
        geteditedindex(state){
            return state.editedIndex
        }
    },
    mutations:{
        setpexperiences(state,experiences){
            state.pexperiences = experiences
        },
        setEditingExperience(state, experience){
            state.epe = experience
        },
        setEditingIndex(state, index){
            state.editedIndex = index
        }
    },
    actions:{
        getprofessionallist({commit}){
            let did = localStorage.getItem("did")
            axios.get("document/"+did+"/professionals",)
            .then(result=>{
                commit('setpexperiences', result.data.professional)
            })
            .catch(error => {
                console.log(error.response)
            })
        },
        setpExperiences({commit}, pexp){
            let ids = pexp.map(item=>{
                return item.id
            })
            let formData ={
                id: ids
            }
            axios.post('document/sort-professional-experiences', formData)

            commit('setpexperiences', pexp)
        },
        removeExperience({commit}, experience){
            axios.post('document/professional/'+experience.id+"/delete")
            .then(response=>{
                commit('setpexperiences', response.data.experiences)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        editExperience({commit}, pl){
          let editingExperience = Object.assign({},pl.experience)
          commit('setEditingExperience', editingExperience)
          commit('setEditingIndex', pl.index)
        },
        setExperience({commit,state},pl){
            Vue.set(state.experiences, state.editedIndex, pl)
        },
        submitForm({commit},formData){

            axios.post('document/professional', formData)
            .then(result=>{
                console.log(result.data.response)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        setProfessionalOpacity({commit, state}, opacity){
            let op = opacity+state.editedIndex
            commit('setOpacity', op)
        },
        addProfessionalExperience({commit, state}){
            let data = {
                resume_id : localStorage.getItem("did")
            }
            axios.post('document/add-professional-experience', data)
            .then(response=>{
                
                commit('setpexperiences', response.data.experiences)
                let index = state.pexperiences.length - 1
                let editingExperience = Object.assign({},response.data.experience)
                commit('setEditingExperience', editingExperience)
                commit('setEditingIndex', index)
                commit('setMenuText', 'pexp')
            })
            .catch(error=>{
                console.log(error.response)
            })
            
        }
    }
}