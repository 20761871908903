import axios from '../axios'
export default {
    state:{
        person:{
            is_show_driving_license: 1,
            name: "Chitano",
            surname: "Kumar Sarker",
            email: "chitanokumar@gmail.com",
            avatar: null,
            address: "Shadullahpur 5730, Gaibandha, Bangladesh",
            phone: "+8801710048212",
            driving_license: "Driving License",
            about:"I am Chitano Kumar Sarker from Bangladesh. I love to do programming, playing card and driving motor byke. I also love to help people to fix there application bug and operating system related bugs.",
            is_show_about: 1,
            resume_id: null,
        }
        
    },
    getters:{
        getPerson(state){
            return state.person
        },
        getAvatar(state){
            return state.person.avatar
        }

    },
    mutations:{
        setPerson(state, person){
            state.person = person
        },
        setAvatar(state, avatar){``
            state.person.avatar = avatar
        },

    },
    actions:{
        setOpacity({commit}, input){
            commit('setOpacity', input)
        },
        setAvatar({commit}, avatar){
            commit('setAvatar', avatar)
        },
        submitContactForm({commit}, payload){
           
            axios.post('document/contact', payload)
            .then(response=>{
                console.log(response)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        getContactDetails({commit, state}){
            let did = localStorage.getItem("did")
            axios.get('document/'+did+"/contact-details")
            .then(response=>{
               commit('setPerson', response.data.contact)
            })
            .catch(error=>{
                console.log(error.response)
            })
        },
        updateAbout({commit}, data){
            axios.post('document/about', data)
            .then(response=>{
                commit('setAbout', data.about)
            })
            .catch(error=>{
                console.log(error.response)
            })
        }


    }
}